import Vue from "vue";
import Router from "vue-router";
import settingsRoutes from "./settings-routes";
import appSettingsRoutes from "./app-settings-routes/apps-settings-route";
import storageService from "@/services/storage-service";

Vue.use(Router);

// Define credit scoring routes with privilege requirements
const creditScoringRoutes = {
  path: "credit-scoring",
  name: "credit-scoring",
  component: () => import("@/views/apps/credit-scoring/CreditScoring.vue"),
  redirect: { name: "credit-dashboard" },
  children: [
    {
      path: "",
      name: "credit-dashboard",
      component: () => import("@/views/apps/credit-scoring/Dashboard.vue"),
      meta: {
        // No specific privilege required for dashboard
      },
    },
    {
      path: "upload-data",
      name: "upload-data",
      component: () => import("@/views/apps/credit-scoring/Credit-Score-Upload.vue"),
      meta: {
        privilege: {
          app: "Credit Scoring Model",
          name: "Upload Data",
        },
      },
    },
    {
      path: "mass-report",
      name: "mass-report",
      component: () => import("@/views/apps/credit-scoring/Mass-Report.vue"),
      meta: {
        privilege: {
          app: "Credit Scoring Model",
          name: "Generate Reports",
        },
      },
    },
    {
      path: "view-reports",
      name: "view-reports",
      component: () => import("@/views/apps/credit-scoring/View-Reports.vue"),
      meta: {
        privilege: {
          app: "Credit Scoring Model",
          name: "Generate Reports",
        },
      },
    },
    {
      path: "risk-rating-settings",
      name: "risk-rating-settings",
      component: () => import("@/views/apps/credit-scoring/Risk-Rating-Settings.vue"),
      meta: {
        privilege: {
          app: "Credit Scoring Model",
          name: "Configure Risk Grades",
        },
      },
    },
  ],
};

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "",
      component: () => import(/* webpackChunkName: "login" */ "@/views/Index.vue"),
      children: [
        {
          path: "/",
          name: "",
          component: () => import(/* webpackChunkName: "login" */ "@/layouts/Public-Layout.vue"),
          redirect: {
            name: "login",
          },
          children: [
            {
              path: "login",
              name: "login",
              component: () => import(/* webpackChunkName: "login" */ "@/views/Login.vue"),
            },
            {
              path: "logout",
              name: "logout",
              component: () => import(/* webpackChunkName: "login" */ "@/views/Logout.vue"),
            },
            {
              path: "setup/:token",
              name: "setup",
              component: () => import(/* webpackChunkName: "login" */ "@/views/Setup.vue"),
            },
            {
              path: "forgot/password/:token",
              name: "forgot-password",
              component: () =>
                import(/* webpackChunkName: "login" */ "@/views/ResetForgottenPasswordView.vue"),
            },
            {
              path: "edit-loans",
              name: "edit-loans",
              component: () =>
                import(/* webpackChunkName: "login" */ "@/views/Edit-Loan-Information.vue"),
            },
          ],
        },
        {
          path: "/",
          name: "",
          component: () =>
            import(/* webpackChunkName: "login" */ "@/layouts/Maintenance-Layout.vue"),
          redirect: {
            name: "login",
          },
          children: [
            {
              path: "maintenance",
              name: "maintenance",
              component: () => import(/* webpackChunkName: "login" */ "@/views/Maintenance.vue"),
            },
          ],
        },
        {
          path: "app",
          name: "app",
          component: () => import(/* webpackChunkName: "login" */ "@/layouts/App-Layout.vue"),
          redirect: {
            name: "modules",
          },
          children: [
            {
              path: "account-settings",
              name: "account-settings",
              component: () =>
                import(/* webpackChunkName: "login" */ "@/views/settings/Account-Settings.vue"),
            },
            {
              path: "user-stat",
              name: "user-stat",
              component: () =>
                import(/* webpackChunkName: "login" */ "@/views/apps/UserAuditReports.vue"),
            },
            {
              path: "audit-reports",
              name: "audit-reports",
              component: () =>
                import(/* webpackChunkName: "login" */ "@/views/apps/AuditReports.vue"),
            },
            {
              path: "release-notes",
              name: "release-notes",
              component: () =>
                import(/* webpackChunkName: "login" */ "@/views/apps/ReleaseNotes.vue"),
            },
            {
              path: "modules",
              name: "modules",
              component: () => import(/* webpackChunkName: "login" */ "@/views/apps/Apps.vue"),
            },
            {
              path: "customer-management",
              name: "customer-management",
              component: () =>
                import(
                  /* webpackChunkName: "login" */ "@/views/apps/customer-management/Customer-Container.vue"
                ),
              redirect: {
                name: "customers",
              },
              children: [
                {
                  path: "dashboard",
                  name: "dashboard",
                  component: () =>
                    import(
                      /* webpackChunkName: "login" */ "@/views/apps/customer-management/CustomerDashboard.vue"
                    ),
                },
                {
                  path: "customers",
                  name: "customers",
                  component: () =>
                    import(
                      /* webpackChunkName: "login" */ "@/views/apps/customer-management/CustomerList.vue"
                    ),
                },
              ],
            },
            {
              path: "account-management",
              name: "acoount-management",
              component: () =>
                import(
                  /* webpackChunkName: "login" */ "@/views/apps/account-management/Account-Listing.vue"
                ),
              redirect: {
                name: "accounts",
              },
              children: [
                {
                  path: "accounts",
                  name: "accounts",
                  component: () =>
                    import(
                      /* webpackChunkName: "login" */ "@/views/apps/account-management/Account-Listing.vue"
                    ),
                },
              ],
            },
            // Replace the credit scoring route with our updated version
            creditScoringRoutes,
            settingsRoutes,
            appSettingsRoutes,
          ],
        },
      ],
    },
  ],
});

// Router guard with privilege checking
router.beforeEach((to, from, next) => {
  let authRequired = true;
  let loggedIn = storageService.getItem("user");
  const publicPages = ["/", "/login", "/setup", "/logout", "forgot/password"];

  // Redirect to app modules if user is already logged in
  if (to.path === "/login" && loggedIn) {
    next("/app");
    return;
  }

  for (const el of publicPages) {
    if (to.path.indexOf(el) > -1) {
      authRequired = false;
      break;
    }
  }

  // Redirect to login page if not logged in and trying to access a restricted page
  if (authRequired && !loggedIn) {
    return next("/login");
  }

  // Check if route requires specific privileges (credit scoring module)
  if (loggedIn && to.matched.some((record) => record.meta && record.meta.privilege)) {
    const user = storageService.getItem("user");

    // Get route that requires privilege check
    const routeWithPrivilege = to.matched.find((record) => record.meta && record.meta.privilege);

    if (routeWithPrivilege) {
      const requiredPrivilege = routeWithPrivilege.meta.privilege;

      // Check if user has the required privilege
      const hasPrivilege =
        user.privileges &&
        user.privileges.some(
          (p) =>
            p.privilege_app === requiredPrivilege.app &&
            p.privilege_name === requiredPrivilege.name &&
            p.is_activated === true,
        );

      if (!hasPrivilege) {
        // User doesn't have the required privilege
        // Redirect to credit scoring dashboard with error message
        if (Vue.prototype.$store) {
          Vue.prototype.$store.dispatch("showSnackbar", {
            text: `Access denied: You don't have the required "${requiredPrivilege.name}" privilege`,
            color: "error",
          });
        }

        // Redirect to the credit scoring dashboard
        next("/app/credit-scoring");
        return;
      }
    }
  }

  next();
});

export default router;
